import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import '../css/Offerings.css'

const Partner = () => {
  return (
      <Container fluid>
        <div className="about-heading-content">
          <Row>
            <div className="col-xl-9 col-lg-10 mx-auto">
              <div className="bg-faded rounded p-5">
                <h2 className="section-heading mb-4">
                  <span className="section-heading-lower">
                    Festivals, Fairs, and more
                  </span>
                </h2>
                <p>
                  Events such as festivals and fairs can be fun for everyone! At Kids First Entertainment, we believe
                  that a family friendly space with art activities and more is a wonderful way to embrace community and
                  encourage families to participate in an event. We create a vibrant and interactive space designed to
                  engage children and families, keeping the little ones entertained for hours. Depending on the needs of
                  your event, this could be as simple as an art station with teacher-led activities or a complete
                  children’s area filled with colorful displays, hands-on projects, and opportunities for imaginative
                  play!
                </p>
                <h2 className="section-heading mb-4">
                  <span className="section-heading-lower">
                    Offerings
                  </span>
                </h2>
                <p>
                  There are engaging and exciting art and music stations, play areas for both calming and energetic
                  activities. Here are some examples of the fun we can bring to your next event.
                </p>
                <h2 className="section-heading mb-4">
                  <span className="section-heading-lower">
                    Creative Stations:
                  </span>
                </h2>
                <p>
                  <ul>
                    <li>
                      <b>Crafting Corner:</b> Tables with various art supplies such as paints, markers, glue, and
                      recycled materials for kids to create their own masterpieces.
                    </li>
                    <li>
                      <b>Collaborative Art Area:</b> A large canvas, mural, or other work together activity where
                      children can contribute their artwork, promoting teamwork and creativity.
                    </li>
                    <li>
                      <b>Art Workshops:</b> Scheduled sessions where local artists lead fun, guided projects
                      (e.g., beading, rainbow loom, painting, or other types of crafting).
                    </li>
                  </ul>
                </p>
                <h2 className="section-heading mb-4">
                  <span className="section-heading-lower">
                    Play Areas:
                  </span>
                </h2>
                <p>
                  <ul>
                    <li>
                      <b>Imagination Zone:</b> A soft play area with themed props and costumes for imaginative play.
                    </li>
                    <li>
                      <b>Outdoor Games:</b> Outdoor games like Capture the Flag and the Floor is Lava.
                    </li>
                    <li>
                      <b>Giant Fun:</b> Connect 4 and Jenga but make it life size.
                    </li>
                  </ul>
                </p>
                <h2 className="section-heading mb-4">
                  <span className="section-heading-lower">
                    Interactive Installations:
                  </span>
                </h2>
                <p>
                  <ul>
                    <li>
                      <b>Community Art Projects:</b> Large collaborative installations where attendees can contribute
                    </li>
                    <li>
                      <b>Sensory Stations:</b> Areas with different textures, colors, and materials for sensory
                      exploration. (e.g. Kinetic Sand/ Modeling Clay).
                    </li>
                    <li>
                      <b>Take-Home Crafts:</b> Craft kits or small projects that kids can complete and take home,
                      encouraging continued creativity beyond the festival.
                    </li>
                  </ul>
                </p>
                <p>
                  <br/>
                  KFE can provide all of this and more at your next event.
                  <br/><br/>
                  Need something a little different? We’re always open to new ideas and would love to hear from you.
                  <br/><br/>
                  To inquire more about partnering with us, please
                  contact <a className={'link-success'}
                             href="mailto:megan@kidsfirstentertainment.com">megan@kidsfirstentertainment.com</a>
                </p>
                <br/>
              </div>
            </div>
          </Row>
        </div>
      </Container>
  );
};

export default Partner;