import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

const Contact = () => {
  return (
      <Container fluid>

        {/*img.img-fluid.rounded.about-heading-img.mb-3.mb-lg-0(src='assets/img/about.jpg', alt='...')*/}
        <div className="about-heading-content">
          <Row>
            <div className="col-xl-9 col-lg-10 mx-auto">
              <div className="bg-faded rounded p-5">
                <h2 className="section-heading mb-4">
                  <span className="section-heading-lower">
                    Bookings
                  </span>
                </h2>

                <p>
                  Fill out our <a className={'link-success'} href="https://form.jotform.com/241336798418164">Bookings Request Form</a> to
                  reserve our services at your event. Once we receive the booking request,
                  we will send a booking confirmation and invoice.
                </p>
                <h2 className="section-heading mb-4">
                  {/*<span className="section-heading-upper">*/}
                  {/*  The Company*/}
                  {/*</span>*/}
                  <span className="section-heading-lower">
                    Contact
                  </span>
                </h2>
                <p>
                  If you have any questions, please reach out by sending an email to <a className={'link-success'} href="mailto:megan@kidsfirstentertainment.com">
                  megan@kidsfirstentertainment.com</a>. We will get back to you as soon as possible, within 72 hours.
                </p>
              </div>
            </div>
          </Row>
        </div>
      </Container>
  );
};

export default Contact;