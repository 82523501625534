import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import '../css/Offerings.css'

const Offerings = () => {
  return (
      <Container fluid>
        <div className="about-heading-content">
          <Row>
            <div className="col-xl-9 col-lg-10 mx-auto">
              <div className="bg-faded rounded p-5">
                <h2 className="section-heading mb-4">
                  {/*<span className="section-heading-upper">*/}
                  {/*  The Company*/}
                  {/*</span>*/}
                  <span className="section-heading-lower">
                    Wedcare
                  </span>
                </h2>
                <p>
                  Do you have a wedding or other specialized event coming up? Let us take care of entertaining the kids!
                  Our premier wedcare service is designed to ensure every kid is having a great time so that parents are
                  too. We focus on kids first by providing high quality fun and engaging activities. We are truly
                  invested in their happiness and know when kids are having fun, events proceed smoothly.
                  Book our services today and enjoy your event to the fullest knowing we're taking care of everything
                  with the young crowd!
                </p>
                <br/>
                <h2 className="section-heading mb-4">
                  {/*<span className="section-heading-upper">*/}
                  {/*  The Company*/}
                  {/*</span>*/}
                  <span className="section-heading-lower">
                    Why Wedcare?
                  </span>
                </h2>
                <p>
                  There are many reasons why it is beneficial for children to be able to attend a wedding or other
                  specialized event. It can be difficult for guests to leave their kids behind and individual childcare
                  for each family can be expensive. We love to make it possible for clients to have all their friends
                  and family attend the big day, including those with children. When kids play, adults can stay!
                </p>
                <br/>

                {/*<h2 className="section-heading mb-4">*/}
                {/*  /!*<span className="section-heading-upper">*!/*/}
                {/*  /!*  The Owner*!/*/}
                {/*  /!*</span>*!/*/}
                {/*  <span className="section-heading-lower">*/}
                {/*    Birthday Parties*/}
                {/*  </span>*/}
                {/*</h2>*/}
                {/*<p>*/}
                {/*  In addition to providing entertainment for children at adult events, we are currently offering*/}
                {/*  our entertainment packages at children’s birthday parties. We know birthdays are a special time*/}
                {/*  and we are here to help make it worry free and full of fun! We come prepared with specially*/}
                {/*  curated activities to go with our craft table, group outdoor/indoor games, and music.*/}
                {/*  Choose one of our exciting themes or message us a request and we will try our best to accommodate!*/}
                {/*</p>*/}
                {/*<br/>*/}
                {/*<h2 className="section-heading mb-4">*/}
                {/*  /!*<span className="section-heading-upper">*!/*/}
                {/*  /!*  The Owner*!/*/}
                {/*  /!*</span>*!/*/}
                {/*  <span className="section-heading-lower">*/}
                {/*    Themes*/}
                {/*  </span>*/}
                {/*</h2>*/}
                {/*<Container className="themes">*/}
                {/*  <Row>*/}
                {/*    <Col xs={6} md={3} className="border border-success">Bug Out</Col>*/}
                {/*    <Col xs={6} md={3} className="border border-success">Under the Sea</Col>*/}
                {/*    <Col xs={6} md={3} className="border border-success">Pokemon</Col>*/}
                {/*    <Col xs={6} md={3} className="border border-success">Minecraft</Col>*/}

                {/*    <Col xs={6} md={3} className="border border-success">Taylor Swift</Col>*/}
                {/*    <Col xs={6} md={3} className="border border-success">Spooky</Col>*/}
                {/*    <Col xs={6} md={3} className="border border-success">Jungle</Col>*/}
                {/*    <Col xs={6} md={3} className="border border-success">Frozen</Col>*/}

                {/*    <Col xs={6} md={3} className="border border-success">Super Mario</Col>*/}
                {/*    <Col xs={6} md={3} className="border border-success">Lego</Col>*/}
                {/*    <Col xs={6} md={3} className="border border-success">Star Wars</Col>*/}
                {/*    <Col xs={6} md={3} className="border border-success">Sports</Col>*/}

                {/*    <Col xs={6} md={3} className="border border-success">Hello Kitty</Col>*/}
                {/*    <Col xs={6} md={3} className="border border-success">Slime</Col>*/}
                {/*    <Col xs={6} md={3} className="border border-success">Mermaids & Pirates</Col>*/}
                {/*    <Col xs={6} md={3} className="border border-success align-middle">Theme of Your Choice</Col>*/}
                {/*  </Row>*/}
                {/*</Container>*/}
                {/*<br/>*/}
                <h2 className="section-heading mb-4">
                  <span className="section-heading-lower">
                    Packages
                  </span>
                </h2>
                <p>
                  <b>Entertainment Package #1: Crafty Kids</b><br/>
                  Time: 2 hours (up to 12 children)<br/>
                  Cost: $375
                </p>

                <p className="fixed-line">
                  This package includes 2 hours of crafting fun! Our experienced childcare professionals come
                  prepared with crafty activities for kids of all ages to ensure every child is engaged and
                  entertained the whole time. See your kids beam with pride when they show you their make and take
                  home crafts.
                </p>
                <br/>

                <p>
                  <b>Entertainment Package #2: Active Kids</b><br/>
                  Time: 2 hours (up to 12 children)<br/>
                  Cost: $425
                </p>
                <p>
                  Enjoy 2 hours of dynamic indoor crafting and outdoor activities designed to keep kids active and
                  entertained! Our childcare professionals lead exciting games like capture the flag and the floor is
                  lava, with plenty of music and dancing to specially curated playlists. It’s a high-energy package
                  that ensures kids have a blast!
                </p>
                <br/>

                <p>
                  <b>Entertainment Package # 3: Crafty & Active Kids</b><br/>
                  Time: 3 hours (up to 12 children)<br/>
                  Cost: $550
                </p>
                <p>
                  Get the best of both worlds with 3 hours of crafting and outdoor activities! Our skilled childcare
                  professionals ensure a seamless blend of creative crafts and energetic games. Kids will enjoy
                  everything from capture the flag and the floor is lava to crafting high-quality projects, finishing
                  off with music and dancing to fun, age-appropriate playlists.
                </p>

                <h2 className="section-heading mb-4">
                  <span className="section-heading-lower">
                    Add ons:
                  </span>
                </h2>
                À la carte
                <br/>
                <ul>
                  <li>13-20 children: $250</li>
                  <li>21-30 children: $500</li>
                  <li>+ 1 hour: $175</li>
                  <li>+ 2 hours: $350</li>
                  <li>+ 3 hours: $525</li>
                  <li>Snack Provisions: $60-$120</li>
                </ul>
                <p className="fixed-line">
                  *We are an inclusive company and value all children. We offer a sliding scale to ensure all
                  families may enjoy our services. Please
                  contact <a className={'link-success'}
                             href="mailto:megan@kidsfirstentertainment.com">megan@kidsfirstentertainment.com</a> to
                  inquire.
                </p>
                <p>
                  For bookings, please go to our <a className={'link-success'} href="/Contact">Contact</a> page.
                </p>
              </div>
            </div>
          </Row>
        </div>
      </Container>
  );
};

export default Offerings;