import { Outlet, Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import '../css/Layout.css'
import NavDropdown from "react-bootstrap/NavDropdown";
import Header from "./Header";

const Layout = () => {
  return (
    <>
      <Navbar expand="lg" className="navbar-dark main-navbar" bg="dark" variant="dark" id="mainNav">
        <Container>
          <Navbar.Brand href="/">Kids First Entertainment</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="entertainment">Entertainment</Nav.Link>
              <Nav.Link href="enrichment">Enrichment</Nav.Link>
              <Nav.Link href="partner">Partner</Nav.Link>
              <Nav.Link href="about">About</Nav.Link>
              <Nav.Link href="contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Header />

      <Outlet/>
    </>
)
};

export default Layout;