import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import '../css/Offerings.css'

const Offerings = () => {
  return (
      <Container fluid>
        <div className="about-heading-content">
          <Row>
            <div className="col-xl-9 col-lg-10 mx-auto">
              <div className="bg-faded rounded p-5">
                <h2 className="section-heading mb-4">
                  <span className="section-heading-lower">
                    Art Enrichment
                  </span>
                </h2>
                <p>
                  Welcome to Kids First Entertainment art enrichment, where students embark on an exciting journey of
                  creativity and self-expression! Our art enrichment class is designed specifically for children in TK
                  through 5th grade, providing a fun and supportive environment for them to learn new skills and build
                  confidence. From beading to rainbow loom and embroidery to crochet, students discover the joy of being
                  a maker through hands-on crafting!
                </p>
                <h2 className="section-heading mb-4">
                  <span className="section-heading-lower">
                    Classes
                  </span>
                </h2>
                <p>
                  We are currently offering two classes, Mini Makers and Afternoon Artisans serving grades TK-5th.
                </p>
                <h2 className="section-heading mb-4">
                  <span className="section-heading-lower">
                    Mini Makers
                  </span>
                </h2>
                <p>
                  Step into a world of color and creativity in our Mini Makers class designed especially for the young
                  creators! This dynamic and engaging course offers young learners the opportunity to explore three
                  exciting handicrafts: beading, rainbow loom, and embroidery. Through hands-on activities and guided
                  instruction, children will learn new skills, build self confidence, and have fun while doing it!
                </p>
                <h2 className="section-heading mb-4">
                  <span className="section-heading-lower">
                    Afternoon Artisans
                  </span>
                </h2>
                <p>
                  Welcome to Afternoon Artisans, where artists delve into the colorful world of textiles and fibers!
                  This dynamic class offers an exciting exploration of four engaging crafts: beading, rainbow loom,
                  embroidery, and crochet. From intricate beadwork to crocheting amigurumi toys, there’s something for
                  everyone! We can’t wait to see what your makers create.
                </p>
                <h2 className="section-heading mb-4">
                  <span className="section-heading-lower">
                    Benefits & Skills
                  </span>
                </h2>

                  <ul>
                    <li>
                      <b>Explore Handicrafts:</b> Students will discover the versatility of textiles and fibers as they
                      explore different crafting techniques like beading, rainbow loom, embroidery, and crochet.
                    </li>
                    <li>
                      <b>Develop Fine Motor Skills:</b> Beading, loom, stitching, and crochet will help students refine
                      their fine motor skills, dexterity, and hand-eye coordination.
                    </li>
                    <li>
                      <b>Encourage Creativity:</b> Hands-on projects and guided instruction give students the
                      opportunity to express their creativity, experiment with different materials and techniques,
                      and create unique works of art.
                    </li>
                    <li>
                      <b>Patience and Persistence:</b> Learning intricate crafting techniques requires both. Students
                      will develop resilience and perseverance as they work through challenges and refine their skills.
                    </li>
                    <li>
                      <b>Promote Self-Expression:</b> Artistic expression is a powerful tool for self-discovery and
                      communication. Students will have the freedom to express themselves through their choice of
                      colors, patterns, and designs in their textile creations.
                    </li>
                  </ul>

                <h2 className="section-heading mb-4">
                  <span className="section-heading-lower">
                    Join us
                  </span>
                </h2>
                <p>
                  Our classes adopt a hands-on, experiential approach to learning, allowing students to learn through
                  exploration, experimentation, and creative expression. The instructor serves as a guide and mentor,
                  providing demonstrations, instructions, and individualized support to help students develop their
                  skills and unleash their creativity. Get ready to bead, loom, stitch, and crochet the afternoon away!
                  We can't wait to see what your makers create!
                </p>
                <p>
                  To inquire more about our enrichment program, please
                  contact <a className={'link-success'}
                             href="mailto:megan@kidsfirstentertainment.com">megan@kidsfirstentertainment.com</a>
                </p>
                <br/>
              </div>
            </div>
          </Row>
        </div>
      </Container>
  );
};

export default Offerings;