import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../css/About.css'

const About = () => {
  return (
      <Container fluid>

        {/*img.img-fluid.rounded.about-heading-img.mb-3.mb-lg-0(src='assets/img/about.jpg', alt='...')*/}
        <div className="about-heading-content">
          <Row>
            <div className="col-xl-9 col-lg-10 mx-auto">
              <div className="bg-faded rounded p-5">
                <h2 className="section-heading mb-4">
                  <span className="section-heading-upper">
                    The Company
                  </span>
                  <span className="section-heading-lower">
                    Kids First Entertainment
                  </span>
                </h2>
                <p>
                  Kids First Entertainment specializes in curating art and entertainment for children to maximize
                  enjoyment for all. Our mission is to ensure that every kid is having a great time so adults can too!
                  Whether you’re looking to add a quality art class to your school or fully entertain your young wedding
                  guests, we strive to make a difference in the lives of children and their families.

                </p>
                <br />
                <h2 className="section-heading mb-4">
                  <span className="section-heading-upper">
                    The Owner
                  </span>
                  <span className="section-heading-lower">
                      Megan Cecil
                  </span>
                </h2>
                <div>
                  <img src={'/1000005586.png'}/>
                </div>
                <br />
                <p>
                  KFE got its start when I decided to combine my 20 years of experience as an educator and child care
                  professional with my love of providing a high quality experience for both parents and their children.
                  Quality art programs enhance children’s lives as they learn new skills and build self confidence.
                  Making sure children can feel pride as they participate in an event brings me joy. As a parent, I
                  realize how difficult it is to be present in the moment at an event when I also need to keep an eye
                  on my child. That’s where Kids First Entertainment comes in. Our mission is providing fun that
                  focuses on kids first, so everyone can enjoy themselves.
                  </p>
              </div>
            </div>
          </Row>
        </div>
      </Container>
  );
};

export default About;