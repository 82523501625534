import HomeCarousel from "./HomeCarousel";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../css/Home.css'
import Header from "./Header";

const Home = () => {
  return (
    <>
      <Container fluid>
        <Row className="justify-content-md-center">
          {/*d-md-block mx-auto*/}
          <div className="col-xs-12 center-image" >
            <div className="d-none d-sm-block">
              <HomeCarousel/>
            </div>
          </div>
        </Row>
        <Row className="page-section-row">
          <section className="page-section cta">
            <Container>
              <Row>
                <div className="col-xl-9 mx-auto">
                  <div className="cta-inner bg-faded text-center rounded">
                    <h2 className="section-heading mb-4">
                      <span className="section-heading-upper">
                        Our
                      </span>
                      <span className="section-heading-lower">
                        Specialty
                      </span>
                    </h2>
                    <p className="mb-0">
                      Providing high quality children's entertainment and art programs.
                    </p>
                  </div>
                </div>
              </Row>
            </Container>
          </section>
        </Row>
      </Container>
    </>
)
};

export default Home;