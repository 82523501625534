import Carousel from 'react-bootstrap/Carousel';

function ControlledCarousel() {
  // const [index, setIndex] = useState(0);
  //
  // const handleSelect = (selectedIndex) => {
  //   setIndex(selectedIndex);
  // };

  // activeIndex={index} onSelect={handleSelect}

  return (
      <Carousel fluid>
        <Carousel.Item>
          <center>
          <img className="d-block mx-auto" width={800} height={400} src={"/5.png"}/>
          </center>
          {/*<ExampleCarouselImage text="First slide" />*/}
          <Carousel.Caption>
            <h3>Children's Wedding Entertainment</h3>
            {/*<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block mx-auto" width={800} height={400} src={"/7.png"}/>
          <Carousel.Caption>
            <h3>School Art Programs</h3>
            {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block mx-auto" width={800} height={400} src={"/6.png"}/>
          {/*<ExampleCarouselImage text="Third slide" />*/}
          <Carousel.Caption>
            <h3>Exciting Outdoor Games</h3>
            {/*<p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>*/}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block mx-auto" width={800} height={400} src={"/1.png"}/>
          {/*<ExampleCarouselImage text="Second slide" />*/}
          <Carousel.Caption>
            <h3>Custom Crafts</h3>
            {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block mx-auto" width={800} height={400} src={"/2.png"}/>
          {/*<ExampleCarouselImage text="Second slide" />*/}
          <Carousel.Caption>
            <h3>Guided Activities</h3>
            {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
          </Carousel.Caption>
        </Carousel.Item>

        {/*<Carousel.Item>*/}
        {/*  <img className="d-block mx-auto" width={800} height={400} src={"/4.png"}/>*/}
        {/*  <Carousel.Caption>*/}
        {/*    <h3>Take Home Art</h3>*/}
        {/*    /!*<p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>*!/*/}
        {/*  </Carousel.Caption>*/}
        {/*</Carousel.Item>*/}
      </Carousel>
  );
}

export default ControlledCarousel;