import "../css/Header.css"

const Header = () => {
  return (
      <header>
        {/*d-none d-lg-block*/}
        <h1 className="site-heading text-center text-faded">
          <span className="site-heading-lower">
            Kids First Entertainment
          </span>
          <br />
          <span className="site-heading-subtext site-heading-upper mb-3">
            Oakland, Berkeley, and surrounding areas
          </span>
        </h1>
      </header>
  );
};

export default Header;
